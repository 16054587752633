import React from "react";
import Layout from "components/layout";
import Seo from "components/seo/seo";
import FastDeliveryForm from "components/growth/forms/live-new-normal";

import bgCustomers from "images/bg_customers.jpg";

import BimboLogo from "images/growth-brand-logos/bimbo.png";
import CruzVerdeLogo from "images/growth-brand-logos/cruzverde.png";
import FarmatodoLogo from "images/growth-brand-logos/farmatodo.png";
import JumboLogo from "images/growth-brand-logos/jumbo.png";
import EasyLogo from "images/growth-brand-logos/easy.png";
import MercadoLibreLogo from "images/growth-brand-logos/merecadolibre.png";
import NestleLogo from "images/growth-brand-logos/nestle.png";
import OxxoLogo from "images/growth-brand-logos/oxxo.png";
import RappiLogo from "images/growth-brand-logos/rappi.png";
import TugoLogo from "images/growth-brand-logos/tugo.png";

import ListCheckIcon from "images/list-check-icon.svg";

import TechImg from "images/growth/same-day/tech.svg";
import OptImg from "images/growth/same-day/opt.svg";
import ServImg from "images/growth/same-day/serv.svg";

import "pages/growth/fast-delivery.css";

const logos = [
  TugoLogo,
  JumboLogo,
  RappiLogo,
  EasyLogo,
  NestleLogo,
  MercadoLibreLogo,
  BimboLogo,
  CruzVerdeLogo,
  FarmatodoLogo,
  OxxoLogo,
];

const NewNormal = () => (
  <Layout className="fast-delivery">
    <Seo 
      title='liftit' 
      description='make same day deliveries'
    />
    <div className="global-wrapper">
      <head>
        <h4>LOGÍSTICA</h4>
        <h1>NUEVA NORMALIDAD 
EN LA LOGÍSTICA</h1>

        <p>
          <b>Acompañanos en este gran conversatorio.</b>Estaremos en vivo este jueves 29 de julio a las 12:00 p.m. hora Colombia, con CasaIdeas, Eterna, T21 tres grandes compañías que han estructurado estrategias para entregar pedidos de forma eficiente en medio del avistamiento de la nueva normalidad y las necesidades actuales de los clientes.
        </p>

        <ul style={{ listStyleImage: `url(${ListCheckIcon})` }}>
          <li>¿Cuál es el panorama de las entregas en la nueva normalidad?
</li>
          <li>¿Cómo estructurar un modelo de entrega eficiente?
</li>
          <li>¿Cuáles son los cambios que en la nueva normalidad serán importantes para entregar pedidos?</li>
        </ul>
      </head>

      <div className="form">
        <FastDeliveryForm />
      </div>

      <div
        className="customers"
        style={{ backgroundImage: `url(${bgCustomers})` }}
      >
        <span>Ellos confían en nosotros</span>
        <div className="brands">
          {logos.map((logo) => (
            <img src={logo} alt={logo} />
          ))}
        </div>
      </div>

      <div className="features">
        <h4>Nos vemos este jueves 29 de julio 2021</h4>

        <div className="feature">
          <img src={TechImg} alt="Tecnologia" />
          <h5 className="feature-title">Última milla</h5>
          <p>
          Domina grandes volúmenes de entrega con cumplimiento garantizado, brindando una experiencia diferenciada de entrega a tus clientes que sea increible.
            
          </p>
          
        </div>
        <div className="feature">
          <img src={OptImg} alt="Operatividad" />
          <h5 className="feature-title">Operatividad</h5>
          <p>
            Flexibiliza tus entregas a través de nuestra amplia flota de
            vehículos y brinda a tus clientes una excelente experiencia de
            compra y entrega para fidelizarlos
          </p>
        </div>
        <div className="feature">
          <img src={ServImg} alt="Servicio" />
          <h5 className="feature-title">Servicio</h5>
          <p>
            Notifica a tus clientes el estado de la entrega, permiteles ubicar
            fácilmente sus productos y extiende tu marca través del trato
            profesionalizado de nuestros Lifters{" "}
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NewNormal;
