import React from "react";
import Person from "images/avatar.png";
import { useForm } from "react-hook-form";

const requiredMessage = "Este campo es requerido";
const emailMessage = "No es un email válido";
const phoneMessage =
  "Introduzca un número de teléfono con un mínimo de 7 dígitos.";

const portalId = "4838246";
const formGuid = "f8f7ca6b-0bc2-4fce-ac0f-57078c3adb3c";

const OmnicanalidadForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [leadCreated, setLeadCreated] = React.useState(false);
  const [response, setResponse] = React.useState("");

  const onSubmit = (form) => {
    const withDefault = {
      ...form
    };
    delete withDefault.deliveries;
    delete withDefault.maturity;
    const parsed = Object.keys(withDefault).reduce(
      (acc, i) => [...acc, { name: i, value: withDefault[i] }],
      []
    );

    const data = {
      fields: parsed,
    };

    const fetchData = async () => {
      const result = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (result.ok) {
        setLeadCreated(true);
        const json = await result.json();
        setResponse(json.inlineMessage);
      }
    };

    fetchData();
  };

  return leadCreated ? (
    <div
      className="response-text"
      dangerouslySetInnerHTML={{ __html: response }}
    ></div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="human-talk">
        <div className="avatar">
          <img src={Person} alt="person" />
        </div>
        <div className="information">
          <p>Este evento te ayudará a potencializar tu negocio</p>
          <h6>Separa tu lugar.</h6>
        </div>
      </div>

      <div className="input_middle">
        <div className="input-container">
          <input
            type="text"
            placeholder="Nombre"
            className={errors.firstname && "required"}
            {...register("firstname", { required: true })}
          />
          {errors.firstname && <small>{requiredMessage}</small>}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Apellido"
            className={errors.lastname && "required"}
            {...register("lastname", { required: true })}
          />
          {errors.lastname && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input-container">
        <input
          type="text"
          placeholder="Correo empresarial"
          className={errors.email && "required"}
          {...register("email", {
            required: true,
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
          })}
        />
        {errors.email &&
          (errors.email.type === "required" ? (
            <small>{requiredMessage}</small>
          ) : (
            <small>{emailMessage}</small>
          ))}
      </div>

      <div className="input_middle">
        <div className="input-container">
          <select
            className={errors.country && "required"}
            {...register("country", { required: true })}
          >
            <option hidden disabled selected value="">
              País
            </option>
            <option value="Colombia">Colombia</option>
            <option value="Mexico">México</option>
            <option value="Chile">Chile</option>
            <option value="Brasil">Brasil</option>
            <option value="Ecuador">Ecuador</option>
          </select>
          {errors.country && <small>{requiredMessage}</small>}
        </div>
        <div className="input-container">
          <input
            type="phone"
            min="1"
            placeholder="# de teléfono"
            className={errors.phone && "required"}
            {...register("phone", { required: true, minLength: 7 })}
          />
          {errors.phone &&
            (errors.phone.type === "required" ? (
              <small>{requiredMessage}</small>
            ) : (
              <small>{phoneMessage}</small>
            ))}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <input
            type="text"
            placeholder="Nombre de la empresa"
            className={errors.company && "required"}
            {...register("company", { required: true })}
          />
          {errors.company && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <input type="submit" value="¡Regístrate ahora!" id="" />
      </div>
    </form>
  );
};

export default OmnicanalidadForm;
